import clsx from 'clsx';
import React, { useRef } from 'react';
import Barcode from 'react-barcode';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

import { BarcodeIcon } from 'icons';

import { useListsPageContext } from 'pages/Lists/provider/ListsPageProvider';
import { Button } from 'components';
import { Typography } from '@mui/material';

type Props = {
  disabled: boolean;
  classNames?: string;
  [key: string]: unknown;
};

const ListPrintBarcode: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { filteredListProducts, selectedLineItems } = useListsPageContext();
  const printRef = useRef(null);
  const handlePrint = useReactToPrint({
    contentRef: printRef,
    pageStyle: `
      @media print {
        @page {
          margin: 0.1in 0.15in 0.1in 0.15in !important;
        },
        .page-break {
          page-break-after: always;
        }
      }`
  });

  const handlePrintWithBarcode = () => {
    /* for testing purposes - still needed */

    // const ids = Object.keys(selectedLineItems);
    // console.log('selectedLineItems: ', ids);
    // console.log('filteredListProducts: ', filteredListProducts);
    handlePrint();
  };
  return (
    <>
      <Button
        className={clsx(props.classNames)}
        {...props}
        data-testid="list-print-button"
        onClick={handlePrintWithBarcode} // 🔸 to-do - placeholder
      >
        <BarcodeIcon width={16} />
        <span>{t('lists.printItem')}</span>
      </Button>

      <div style={{ display: 'none' }}>
        <div ref={printRef} className="">
          {filteredListProducts.map((_product, idx) => {
            // Start a new page container every 12 items (3 rows x 4 columns)
            if (idx % 12 === 0) {
              return (
                <div
                  key={`page-${Math.floor(idx / 12)}`}
                  className="page-break"
                >
                  <div className="grid grid-cols-4 gap-4 p-4">
                    {filteredListProducts
                      .filter((p) =>
                        Object.keys(selectedLineItems).includes(p.productId)
                      )
                      .slice(idx, idx + 12) // Take the next 12 items for this "page"
                      .map((p, subIdx) => (
                        <div
                          key={`barcode-${idx + subIdx}`}
                          className="border-2 !border-black !h-[300px] !min-h-[300px] w-full"
                        >
                          <div className="flex justify-center items-center !h-1/3  p-2">
                            {p.manufacturerNumber ? (
                              <div className="border border-1 w-full flex justify-center">
                                <img
                                  alt=""
                                  className="h-20"
                                  src={p.imageUrls?.thumb?.toString()}
                                />
                              </div>
                            ) : (
                              <div className="border border-1 w-full flex justify-center">
                                <div className="h-20 flex items-center justify-center">
                                  <p className="font-bold">ITEM UNAVAILABLE</p>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="flex flex-col justify-center items-center overflow-hidden max-w-36 mx-auto h-[85px]">
                            <Typography className="text-center" fontSize={10}>
                              Product Name:
                            </Typography>

                            <p className="text-center max-w-36 h-10 line-clamp-2">
                              {p.name}
                            </p>
                            <hr className="w-[90%] mx-auto my-1 px-2 bg-black" />

                            <p className="text-[0.65rem]">
                              MFR #: {p.manufacturerNumber}
                            </p>

                            <p className="text-[0.65rem]">
                              Product ID: {p.productId}
                            </p>
                          </div>
                          <div className="w-full flex flex-col items-center mt-2 ">
                            {/* default width - 2, default height - 100 */}

                            {/* 0.667 scale */}
                            <Barcode
                              width={1.3333}
                              height={66.6666}
                              value={`.${p.productId}`}
                              displayValue={false}
                              margin={0}
                              marginTop={2}
                            />
                            <span className="text-[0.6rem] pb-2">
                              {p.productId}
                            </span>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
    </>
  );
};

export default ListPrintBarcode;
