import {
  useState,
  useContext,
  createContext,
  useMemo,
  useCallback,
  useEffect
} from 'react';

import { merge, noop, uniqBy, isUndefined } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Maybe } from 'yup/lib/types';

import { WrapperProps } from '@reece/global-types';
import {
  useApiAddCartItem,
  useApiDeleteCartItem,
  useApiGetCart,
  useApiGetCartPricingAvailability,
  useApiRemoveAllCartItems,
  useApiUpdateCartDeliveryMethod,
  useApiUpdateCartDeliveryPreferences,
  useApiUpdateCartItemQty,
  useApiUpdateWillCallBranch
} from 'API/cart.api';
import {
  AddToCartItem,
  CartProps,
  DeliveryMethodEnum,
  DeliveryProps,
  ProductProps,
  WillCallProps
} from 'API/types/cart.types';
import {
  Branch,
  CartInput,
  ContractDetails,
  DeliveryInput,
  ErpSystemEnum,
  GetBranchQuery,
  ItemInfo,
  Quote,
  WillCallInput
} from 'generated/graphql';
import { useDomainInfo } from 'hooks/useDomainInfo';
import { useAuthContext } from 'providers/AuthProvider';
import {
  pullContractProductFromMap,
  pullAllContractProducts,
  findCartProductIndex,
  deleteCartItemProductMapping
} from 'providers/libs/CartProvider';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';
import { useToastContext } from 'providers/ToastProvider';
import { trackCartPageViewAction } from 'utils/analytics';
import { format } from 'utils/currency';
import { asyncNoop } from 'utils/etc';

/**
 * CONSTS
 */
export const MAX_CART_ITEMS = 600;

/**
 * Types
 */
export type CartContractType = {
  id: string;
  shipToId: string;
  data?: ContractDetails;
};
export type CartProviderProps = WrapperProps & {
  // specialized to unit test accessor
  testCall?: jest.Mock;
  testCart?: CartProps;
  testContract?: CartContractType;
  testQuoteId?: string;
};
export type CartContextType = {
  addItemToCart: (items: AddToCartItem[]) => Promise<void>;
  addAllListItemsToCart: (listItemsInfo: ItemInfo[]) => void;
  allProductAvailable: boolean;
  badgeCount: number;
  cart?: CartProps;
  cartId?: string;
  checkingOutWithQuote: boolean;
  clearContract: () => void;
  clearQuote: () => void;
  contract?: CartContractType;
  contractBranch?: GetBranchQuery['branch'];
  cartLoading: boolean;
  deleteCartItems: () => void;
  deleteContractItem: (item: string) => void;
  deleteItem: (item: string) => void;
  disableAddToCart: boolean;
  hydrateCartProducts: () => Promise<void>;
  isWillCall: boolean;
  itemCount: number;
  itemAdded?: boolean;
  itemLoading?: string;
  lineNotes: Record<string, string>;
  previousCart?: CartProps;
  quoteData?: Maybe<Quote>;
  quoteId?: Maybe<string>;
  quoteShipToId?: Maybe<string>;
  refreshCart: () => void;
  releaseContractToCart: (
    contract?: ContractDetails,
    qtyMap?: Record<string, string>
  ) => void;
  setCart: (cart?: CartProps) => void;
  setContract: (type?: CartContractType) => void;
  setItemAdded?: (val: boolean) => void;
  setLineNotes: (notes: Record<string, string>) => void;
  setPreviousCart: (cart?: CartProps) => void;
  setQuoteData: (quote: Quote) => void;
  setQuoteId: (quoteId?: string) => void;
  setQuoteShipToId: (item: string) => void;
  setSelectedBranch: (branch: Branch) => void;
  shippingBranchId: string;
  subTotal: string;
  updateCartItemQtyLoading: boolean;
  updateCartDeliveryMethod: (
    cartId: string,
    deliveryMethod: DeliveryMethodEnum
  ) => void;
  updateCartDeliveryPreferences: (
    cartId: string,
    shouldShipFullOrder: boolean
  ) => void;
  updateCartFromQuote: (cartInput: CartInput) => void;
  updateDelivery?: (
    deliveryInfo: DeliveryInput
  ) => Promise<DeliveryProps | undefined>;
  updateItemQuantity: (
    itemId: string,
    quantity: number,
    minIncrementQty: number
  ) => void;
  updateWillCall?: (
    willCallInfo: WillCallInput
  ) => Promise<WillCallProps | undefined>;
  updateWillCallBranch: (cartId: string) => void;
  updateWillCallBranchLoading: boolean;
};

/**
 * Context
 */
export const CartContext = createContext<CartContextType>({
  addItemToCart: asyncNoop,
  addAllListItemsToCart: noop,
  allProductAvailable: true,
  badgeCount: 0,
  cart: undefined,
  cartId: undefined,
  cartLoading: false,
  checkingOutWithQuote: false,
  clearContract: noop,
  clearQuote: noop,
  contract: undefined,
  contractBranch: undefined,
  deleteCartItems: noop,
  deleteContractItem: noop,
  deleteItem: noop,
  disableAddToCart: false,
  hydrateCartProducts: asyncNoop,
  isWillCall: false,
  itemAdded: false,
  itemCount: 0,
  itemLoading: undefined,
  lineNotes: {},
  previousCart: undefined,
  refreshCart: noop,
  releaseContractToCart: noop,
  setCart: noop,
  setContract: noop,
  setItemAdded: noop,
  setLineNotes: noop,
  setPreviousCart: noop,
  setQuoteData: noop,
  setQuoteId: noop,
  setQuoteShipToId: noop,
  setSelectedBranch: noop,
  shippingBranchId: '',
  subTotal: '—',
  updateCartItemQtyLoading: false,
  updateCartDeliveryMethod: noop,
  updateCartDeliveryPreferences: noop,
  updateCartFromQuote: noop,
  updateDelivery: asyncNoop,
  updateItemQuantity: noop,
  updateWillCall: asyncNoop,
  updateWillCallBranch: noop,
  updateWillCallBranchLoading: false
});

/**
 * Component
 */
function CartProvider(props: CartProviderProps) {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();

  /**
   * States
   */
  const [selectedBranch, setSelectedBranch] = useState<Branch>();
  const [cart, setCart] = useState(props.testCart);
  const [subTotal, setSubTotal] = useState(cart?.cartSubtotal ?? '—');
  const [badgeCount, setBadgeCount] = useState(cart?.cartBadgeCount ?? 0);
  const [previousCart, setPreviousCart] = useState<CartProps>();
  const [quoteId, setQuoteId] = useState(props.testQuoteId);
  const [quoteShipToId, setQuoteShipToId] = useState<string>();
  const [quoteData, setQuoteData] = useState<Quote>();
  const [itemLoading, setItemLoading] = useState<string>();
  const [contract, setContract] = useState(props.testContract);
  const [lineNotes, setLineNotes] = useState<Record<string, string>>({});
  const [itemAdded, setItemAdded] = useState(false);
  const [isWillCall, setIsWillCall] = useState(false);

  /**
   * Contexts
   */
  const { isWaterworks } = useDomainInfo();
  const { profile, user } = useAuthContext();
  const { selectedAccounts, isMincron } = useSelectedAccountsContext();
  const { toast } = useToastContext();
  const userId = profile?.userId ?? '';
  const shipToId = selectedAccounts.shipTo?.id ?? '';
  const billToId = selectedAccounts.billTo?.id ?? '';
  const userEmail = user?.email ?? '';

  /**
   * Constants
   */
  const cartId = cart?.id ?? '';
  const checkingOutWithQuote = Boolean(quoteId);
  const invalidCartState = !cart || !userId || !shipToId;

  // 🔴 Error - error handling
  // istanbul ignore next
  const handleCartError = (
    e?: unknown,
    name?: string,
    errorCart?: CartProps
  ) => {
    props.testCall?.('handleCartError');
    console.error(e);
    if (!errorCart) {
      console.error(`[${name}] Error: cart not found`);
    }
    if (!userId) {
      console.error(`[${name}] Error: userId not found`);
    }
    if (!shipToId) {
      console.error(`[${name}] Error: shipTo account id not found`);
    }
  };

  /**
   * Data
   */
  // 🟣 onMount GET - cart
  const {
    refetch: refreshCart,
    loading: cartByIdLoading,
    called: cartCalled
  } = useApiGetCart({
    onCompleted: ({ data }) => {
      setBadgeCount(data!.cartBadgeCount ?? 0);
      !data?.products?.length && setSubTotal('$0.00');
      props.testCall?.('useApiGetCart_complete');
      setCart(
        merge({ ...cart, products: [], removedProducts: [] }, data) as CartProps
      );
      data?.id === cartId && callGetCartPricingAvailability(cartId);
    },
    skip: Boolean(cart || isMincron || isWaterworks),
    onError: (e) => handleCartError(e, 'callRefreshCart', cart)
  });
  // 🟣 lazy GET - product pricing and availability on cart
  const {
    call: callGetCartPricingAvailability,
    loading: getCartPricingAvailabilityLoading
  } = useApiGetCartPricingAvailability({
    onCompleted: ({ data }) => {
      props.testCall?.('useApiGetCartPricingAvailability_complete');
      trackCartPageViewAction({
        billTo: billToId,
        userEmail: userEmail
      });
      setSubTotal(data!.cartSubtotal);
      setCart((prev) => {
        const products = prev?.products?.map((product, i) => {
          const matchedProduct = data.products.find(
            (p) => p.lineItemId === product?.id
          );
          if (matchedProduct) {
            return merge(product, matchedProduct);
          }
          return product;
        });
        return { ...prev, products } as CartProps;
      });
    },
    onError: (e) => handleCartError(e, 'callGetCartPricingAvailability', cart)
  });
  // 🟣 lazy PATCH - Update will call branch on cart
  const { call: updateWillCallBranch, loading: updateWillCallBranchLoading } =
    useApiUpdateWillCallBranch({
      onError: (e) => handleCartError(e, 'updateWillCallBranch', cart)
    });

  // 🟣 lazy PATCH - Update item quantity from cart
  const { call: updateCartItemQty, loading: updateCartItemQtyLoading } =
    useApiUpdateCartItemQty({
      onCompleted: ({ data }) => {
        // The removed products need to be set to null so that the removed items dialog box does not
        //  continue to open every time a line item has an updated qty
        cart!.removedProducts = [];
        const findProductIndex = cart!.products!.findIndex(
          (product) => data.product!.id === product!.lineItemId
        );
        if (findProductIndex !== -1) {
          props.testCall?.('useApiUpdateCartItemQty_found');
          // Already established that products is guaranteed NOT null when findProductIndex is NOT -1
          const products = cart!.products!.map((product, index) =>
            index === findProductIndex ? merge(product, data.product) : product
          );
          setCart({ ...cart, products } as CartProps);
          setSubTotal(data.subtotal);
          setBadgeCount(data.cartBadgeCount);
        }
        stopItemLoading();
      },
      onError: (e) => handleCartError(e, 'updateCartItemQty')
    });
  // 🟣 lazy PATCH - Update delivery preferences
  const {
    call: callUpdateCartDeliveryPreferences,
    loading: updateCartDeliveryPreferencesLoading
  } = useApiUpdateCartDeliveryPreferences({
    onError: (e) => handleCartError(e, 'updateCartDeliveryPreferences')
  });
  // 🟣 lazy PATCH - Update delivery method on Cart
  const {
    call: callUpdateCartDeliveryMethod,
    loading: updateCartDeliveryMethodLoading
  } = useApiUpdateCartDeliveryMethod({
    onCompleted: async () => {
      !isMincron && !isWaterworks && refreshCart().then(hydrateCartProducts);
    },
    onError: (e) => handleCartError(e, 'updateCartDeliveryMethod')
  });
  // 🟣 lazy PATCH - Add line item to cart
  const { call: callAddCartItem, loading: addCartItemLoading } =
    useApiAddCartItem({
      onCompleted: ({ data }) => {
        setItemAdded(data.itemAddedSuccess);
        if (data.itemAddedSuccess) {
          props.testCall?.('useApiAddCartItem_found');
          setBadgeCount(data.cartBadgeCount);
          refreshCart();
        }
        // istanbul ignore next - ignoring this because we may overload tests if we attempt to reach the limit
        if (cart?.products && cart?.products.length >= MAX_CART_ITEMS) {
          toast({ message: t('cart.atLimit'), kind: 'warn' });
        }
      },
      onError: (e) => handleCartError(e, 'callAddCartItem')
    });
  // 🟣 lazy DELETE  - Remove all line items from cart
  const { call: callRemoveAllCartItems, loading: removeAllCartItemsLoading } =
    useApiRemoveAllCartItems({
      onError: (e) => handleCartError(e, 'removeAllCartItems')
    });
  // 🟣 lazy DELETE - Delete line item
  const { call: callDeletCartItem, loading: deleteCartItemsLoading } =
    useApiDeleteCartItem({
      onCompleted: ({ data }) => {
        props.testCall?.('useApiDeleteCartItem_complete');
        setBadgeCount(data.cartBadgeCount);
        setSubTotal(
          // istanbul ignore next
          data.products.length ? data.subtotal : '—'
        );
        setCart((prev) => ({
          ...prev,
          products: deleteCartItemProductMapping(prev, data)
        }));
      }
    });

  /**
   * Memos
   */
  // 🔵 Memo - Item count
  const itemCount = useMemo(() => {
    const selectCart = checkingOutWithQuote
      ? previousCart?.products
      : cart?.products;
    const count = contract
      ? selectCart?.length || 0
      : uniqBy(selectCart, 'erpPartNumber').length;
    return count;
  }, [checkingOutWithQuote, previousCart, cart, contract]);
  const disableAddToCart = itemCount >= MAX_CART_ITEMS;

  /**
   * Callbacks
   */
  // 🟤 CB - Append product pricing and availability to Cart products
  const hydrateCartProducts = useCallback(async () => {
    if (!cart?.products?.length || isMincron) {
      props.testCall?.('hydrateCartProducts_none');
      return;
    }
    await callGetCartPricingAvailability(cartId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartId]);

  // 🟤 CB - Clear Quote
  const clearQuote = () => {
    // istanbul ignore next - Ignore because test kept treating `quoteShipToId` not covered even though `quoteId` is NOT falsy
    if ((quoteId || quoteShipToId) && previousCart) {
      setCart(previousCart);
      setPreviousCart(undefined);
    }
    props.testCall?.('clearQuote');
    setQuoteId(undefined);
    setQuoteShipToId(undefined);
  };

  // 🟤 CB - Delete all items from Cart
  const deleteCartItems = async () => {
    const res = await callRemoveAllCartItems(cartId);
    if (!res?.data) {
      return;
    }
    props.testCall?.('deleteCartItems_success');
    setBadgeCount(0);
    setSubTotal('—');
    setCart(res.data);
  };

  // 🟤 CB - Delete item from Cart
  const deleteItem = (lineItemId: string) => {
    callDeletCartItem(cartId, lineItemId).then(hydrateCartProducts);
  };

  // 🟤 CB - Add item to Cart
  const addItemToCart = async (items: AddToCartItem[]) => {
    // wrapping this because we want this void
    await callAddCartItem({ items });
  };

  // 🟤 CB - Add all list items to Cart
  const addAllListItemsToCart = useCallback(
    (listItemsInfo: AddToCartItem[]) => {
      const items = listItemsInfo.map((item) => ({
        productId: item.productId,
        qty: item.qty,
        minIncrementQty: item.minIncrementQty
      }));
      addItemToCart(items);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // 🟤 CB - calculate contract Subtotal
  const calculateContractSubtotal = (products: ProductProps[]) =>
    products!.reduce(
      (acc, product) => acc + product!.pricePerUnit!! * product.quantity,
      0
    );
  // 🟤 CB - Release contract to cart
  const releaseContractToCart = (
    contractDetails?: ContractDetails,
    qtyMap?: Record<string, string>
  ) => {
    const sameContract =
      contract?.data?.contractNumber === contractDetails?.contractNumber;

    if (!contractDetails?.contractProducts) {
      props.testCall?.('releaseContractToCart_null');
      console.error('Failure to release contract to cart!');
      return;
    }
    if (cart && !contract) {
      props.testCall?.('releaseContractToCart_nocontract');
      setPreviousCart(cart as CartProps);
    }

    const products = qtyMap
      ? pullContractProductFromMap(
          contractDetails,
          qtyMap!,
          sameContract,
          cart?.products!
        )
      : pullAllContractProducts(contractDetails);
    const id = contractDetails?.contractNumber ?? '';

    setCart({
      delivery: { id: '', shouldShipFullOrder: false },
      deliveryMethod: DeliveryMethodEnum.Delivery,
      erpSystemName: ErpSystemEnum.Mincron,
      willCall: { id: '' },
      products,
      id,
      shipToId: contractDetails?.jobName,
      subTotal: format(calculateContractSubtotal(products)),
      cartBadgeCount: products.length
    } as CartProps);

    setSubTotal(format(calculateContractSubtotal(products)));
    setBadgeCount(products.length);
    setContract({ data: contractDetails, id, shipToId: '' });
  };

  // 🟤 CB - Update an item qty in cart
  const updateItemQuantity = (
    itemId: string,
    quantity: number,
    minIncrementQty: number
  ) => {
    if (invalidCartState || !cart.products) {
      handleCartError(undefined, 'updateItemQuantity', cart);
      return;
    }
    if (contract) {
      updateContractItemQty(itemId, quantity);
      return;
    }
    setItemLoading(itemId);
    updateCartItemQty(cartId, {
      lineItemId: itemId,
      qty: quantity,
      minIncrementQty
    });
  };

  // 🟤 CB - Update item in cart with contract
  const updateContractItemQty = (itemId: string, quantity: number) => {
    const { clonedProducts, index } = findCartProductIndex(itemId, cart);
    if (index === -1) {
      return;
    }
    props.testCall?.('updateContractItemQty_done');
    clonedProducts[index]!.lineItemSubtotal = format(
      clonedProducts[index]!.pricePerUnit!! * quantity
    );
    clonedProducts[index]!.quantity = quantity;
    setCart({ ...cart, products: clonedProducts } as CartProps);
    setSubTotal(format(calculateContractSubtotal(clonedProducts)));
  };

  // 🟤 CB - Clear contract
  const clearContract = () => {
    if (contract) {
      props.testCall?.('clearContract_done');
      setContract(undefined);
      setCart(previousCart);
      setSubTotal(previousCart?.cartSubtotal ?? '—');
      setBadgeCount(previousCart?.cartBadgeCount ?? 0);
      setLineNotes({});
    }
  };

  // 🟤 CB - Delete item from cart with contract
  const deleteContractItem = (itemId: string) => {
    const { clonedProducts, index } = findCartProductIndex(itemId, cart);
    if (index === -1) {
      props.testCall?.('deleteContractItem_none');
      return;
    }
    clonedProducts.splice(index, 1);
    // Handle line note deletion
    if (!isUndefined(lineNotes[itemId])) {
      const mutableLineNotes = { ...lineNotes };
      delete mutableLineNotes[itemId];
      props.testCall?.('deleteContractItem_linenote');
      setLineNotes(mutableLineNotes);
    }
    props.testCall?.('deleteContractItem_done');
    setSubTotal(format(calculateContractSubtotal(clonedProducts)));
    setBadgeCount(clonedProducts.length);
    setCart({ ...cart!, products: clonedProducts }); // When this is called, cart is guranteed NOT undefined
  };

  // 🟤 CB - Add Item To Cart
  const updateCartFromQuote = (cartInput: CartInput) => {
    if (!cart) {
      handleCartError('updateCart');
      return;
    }
    props.testCall?.('updateCartFromQuote_done');
    setCart(merge(cart, cartInput) as CartProps);
  };

  // 🟤 Stop Item Loading
  const stopItemLoading = () => setItemLoading(undefined);

  // 🟤 CB - update Cart Delivery Method
  const updateCartDeliveryMethod = (
    cartId: string,
    deliveryMethod: DeliveryMethodEnum
  ) => {
    if (!cart) {
      handleCartError('updateCart');
      return;
    }
    const cartInput = { ...cart, deliveryMethod };
    if (isWaterworks || isMincron) {
      props.testCall?.('updateCartDeliveryMethod_mincron');
      setCart(merge(cart, cartInput) as CartProps);
    } else {
      callUpdateCartDeliveryMethod(cartId, deliveryMethod);
    }
  };

  // 🟤 CB - update Cart Delivery Preferences
  const updateCartDeliveryPreferences = (
    cartId: string,
    shouldShipFullOrder: boolean
  ) => {
    const delivery = { ...cart?.delivery, shouldShipFullOrder };
    if (isWaterworks || isMincron) {
      props.testCall?.('updateCartDeliveryPreferences_mincron');
      setCart({ ...cart, delivery } as CartProps);
    } else {
      callUpdateCartDeliveryPreferences(cartId, shouldShipFullOrder);
    }
  };

  // 🟤 CB - Update Cart's delivery method - used for contract and quote only
  async function updateDelivery(deliveryInfo: DeliveryInput) {
    if (!cart) {
      handleCartError('updateDelivery', cart);
      return;
    }
    if (quoteId || contract) {
      props.testCall?.('updateDelivery_contract');
      const delivery = { ...cart.delivery, ...deliveryInfo } as DeliveryProps;
      setCart({ ...cart, delivery });
      return;
    }
    return cart.delivery;
  }

  // 🟤 CB - Update willCall method - used for contract and quote only
  async function updateWillCall(willCallInfo: WillCallInput) {
    if (!cart) {
      handleCartError('updateWillCallCb');
      return;
    }
    if (quoteId || contract) {
      props.testCall?.('updateWillCall_contract');
      const willCall = { ...cart.willCall, ...willCallInfo } as WillCallProps;
      setCart({ ...cart, willCall });
      return;
    }
    return cart.willCall;
  }

  // 🟡 Effect - refresh cart when billTo or shiptTo changes
  useEffect(() => {
    if (
      cart &&
      cartCalled &&
      selectedAccounts.billTo?.id &&
      selectedAccounts.shipTo?.id
    ) {
      refreshCart().then((res) => {
        res?.data?.willCall?.branchId !== selectedBranch?.branchId &&
          updateWillCallBranch(res?.data?.id ?? '');
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccounts.billTo?.id, selectedAccounts.shipTo?.id]);
  // 🟡 Effect - update will call on cart update
  useEffect(() => {
    setIsWillCall(cart?.deliveryMethod === DeliveryMethodEnum.Willcall);
  }, [cart]);

  /**
   * Render
   */
  return (
    <CartContext.Provider
      value={{
        addItemToCart,
        allProductAvailable: cart?.allProductAvailable ?? true,
        addAllListItemsToCart,
        badgeCount,
        cart,
        cartId,
        checkingOutWithQuote,
        cartLoading:
          cartByIdLoading ||
          getCartPricingAvailabilityLoading ||
          updateCartDeliveryPreferencesLoading ||
          updateCartDeliveryMethodLoading ||
          addCartItemLoading ||
          removeAllCartItemsLoading ||
          deleteCartItemsLoading ||
          updateWillCallBranchLoading,
        clearContract,
        clearQuote,
        contract,
        contractBranch: selectedBranch,
        deleteCartItems,
        deleteContractItem,
        deleteItem,
        disableAddToCart,
        hydrateCartProducts,
        isWillCall,
        itemAdded,
        itemCount,
        itemLoading,
        lineNotes,
        quoteData,
        quoteId,
        previousCart,
        refreshCart,
        releaseContractToCart,
        setCart,
        setContract,
        setItemAdded,
        setLineNotes,
        setPreviousCart,
        setQuoteData,
        setQuoteId,
        setQuoteShipToId,
        setSelectedBranch,
        shippingBranchId: selectedBranch?.id ?? '',
        subTotal,
        updateCartItemQtyLoading,
        updateCartDeliveryMethod,
        updateCartDeliveryPreferences,
        updateCartFromQuote,
        updateDelivery,
        updateItemQuantity,
        updateWillCall,
        updateWillCallBranch,
        updateWillCallBranchLoading
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
}

export const useCartContext = () => useContext(CartContext);

export default CartProvider;
